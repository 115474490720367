<template>
    <v-dialog
        v-model="dialog"
        max-width="290"
        hide-overlay
    >
        <v-card>
            <v-card-title class="headline text-center d-block">{{ $t('modal.confirm_delete_images.title') }}
            </v-card-title>

            <v-card-text>
                <div class="text-center">
                    <v-icon size="70" class="mb-2" color="primary">delete</v-icon>
                    <p>{{ $t('modal.confirm_delete_images.description') }}</p>
                    <v-btn color="primary" min-width="150" depressed rounded @click="onCloseModal">
                        {{ $t("no") }}
                    </v-btn>
                    <v-btn text min-width="150" @click="onConfirm">
                        {{ $t("yes") }}
                    </v-btn>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalConfirmDeleteImages',
    props: {
        imageIndex: {
            required: false
        }
    },
    data: () => ({
        dialog: true
    }),
    methods: {
        onCloseModal() {
            this.$emit('closeModalDeleteImages');
        },
        onConfirm() {
            if (this.imageIndex || this.imageIndex === 0) {
                this.$emit('confirmDeleteImage');
            } else {
                this.$emit('confirmDeleteImages');
            }
            this.$emit('closeModalDeleteImages');
        }
    }
};
</script>
