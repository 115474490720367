<template>
    <div class="survey__question__media">

        <v-alert type="info" dense v-if="min && max">
            {{ $tc("fields.media.min_and_max", 1, { min, max }) }}
        </v-alert>
        <v-alert type="info" dense v-if="min && !max">
            {{ $tc("fields.media.min", min, { min }) }}
        </v-alert>
        <v-alert type="info" dense v-if="!min && max">
            {{ $t("fields.media.max", { max }) }}
        </v-alert>

        <p v-if="hintText">{{ hintText }}</p>

        <div>
            <v-file-input
                :rules="isRequired ? rules : []"
                name="upload"
                ref="upload"
                v-model="file"
                multiple
                @change="onFileChange"
                accept="image/*,video/*,application/pdf"
                :placeholder="$t('file.placeholder')"
                :label="$t('file.label')"
            ></v-file-input>
        </div>

        <div v-if="imagesArray.length > 0 && !viewAllImages">
            <v-list>
                <v-list-item v-for="(image, index) in imagesArray.slice(0, 7)" :key="index">
                    <v-list-item-avatar width="80" height="80">
                        <template v-if="image.type.includes('video')">
                            <v-icon>videocam</v-icon>
                        </template>
                        <template v-else-if="image.type.includes('pdf')">
                            <v-icon>picture_as_pdf</v-icon>
                        </template>
                        <template v-else>
                            <img :src="renderImage(image.fileObject)" style="max-width:120px"/>
                        </template>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        {{ image.name }}
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon color="red" @click="showDeleteImagesModal($event, index)" icon>delete</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="imagesArray.length > 7">
                    <v-list-item-content>
                        {{ $tc('file.and_more', imagesArray.length - 7, { count: imagesArray.length - 7 }) }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>

        <div v-if="imagesArray.length > 0 && viewAllImages">
            <v-list>
                <v-list-item v-for="(image, index) in imagesArray" :key="index">
                    <v-list-item-avatar width="80" height="80">
                        <template v-if="image.type.includes('video')">
                            <v-icon>videocam</v-icon>
                        </template>
                        <template v-else-if="image.type.includes('pdf')">
                            <v-icon>picture_as_pdf</v-icon>
                        </template>
                        <template v-else>
                            <img :src="renderImage(image.fileObject)" style="max-width:120px"/>
                        </template>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        {{ $t("file.file_text", { fileNumber: index + 1 }) }}
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon color="red" @click="showDeleteImagesModal($event, index)" icon>delete</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </div>

        <div class="text-center pb-5" v-if="imagesArray.length > 7">
            <v-btn class="mt-2 mx-auto" color="info" @click="toggleViewAllImages" rounded outlined>
                {{ toggleText }}
            </v-btn>
        </div>
        <div class="text-center mt-3">
            <v-btn color="primary" depressed rounded block class="mb-3" @click="onUploadImages">
                {{ $t('file.upload_multiple') }}
                <v-icon right>cloud_upload</v-icon>
            </v-btn>
        </div>

        <v-btn v-if="imagesArray.length > 0" color="red" depressed rounded outlined block @click="showDeleteImagesModal"
               class="my-3">
            {{ $t("file.remove") }}
            <v-icon right>delete</v-icon>
        </v-btn>

        <v-alert type="error" icon="error_outline" v-if="isErrorImagesMany">
            {{ $t("file.error_max", { max }) }}

        </v-alert>

        <v-alert type="error" icon="error_outline" v-if="isErrorImagesLow">
            {{ $t("file.error_min", { min }) }}

        </v-alert>

        <modal-confirm-delete-images @closeModalDeleteImages="showConfirmDeleteImages = false"
                                     :image-index="currentImageIndex" @confirmDeleteImages="deleteImages"
                                     @confirmDeleteImage="onRemoveFile" v-if="showConfirmDeleteImages"/>
    </div>
</template>

<script>
// import {fileToBase64} from '../../../helpers/helpers';
import { mapState, mapMutations } from 'vuex';
import ModalConfirmDeleteImages from '../Modals/ModalConfirmDeleteImages';

export default {
    name: 'Media',
    components: { ModalConfirmDeleteImages },
    props: {
        questionId: {
            required: true
        },
        min: {
            required: false
        },
        max: {
            required: false
        },
        hintText: {
            default: null
        },
        isRequired: {
            default: true
        }
    },
    data() {
        return {
            rules: [
                v => !!v || this.$t('fields.general.rules.required')
            ],
            imagesArray: [],
            filesArray: [],
            file: null,
            isError: false,
            isErrorImagesMany: false,
            isErrorImagesLow: false,
            viewAllImages: false,
            toggleText: 'View all images',
            showConfirmDeleteImages: false,
            currentImageIndex: null
        };
    },
    computed: {
        ...mapState('surveys', ['filesToQuestions', 'surveyMode'])
    },
    methods: {
        ...mapMutations('surveys', ['setFilesToQuestion']),
        showDeleteImagesModal(evt = null, index = null) {
            if (index || index === 0) {
                this.currentImageIndex = index;
            }
            this.showConfirmDeleteImages = true;
        },
        toggleViewAllImages() {
            this.viewAllImages = !this.viewAllImages;
            if (this.viewAllImages) {
                this.toggleText = this.$t('file.hide_files');
            } else {
                this.toggleText = this.$t('file.show_files');
            }
        },
        onFileChange(files) {
            if (this.validateImageFileExtensionsAndSize(files) && this.validateFileName(files)) {
                // let filePromises = [];
                files.forEach((file, index, array) => {
                    if (file.name === '') {
                        return;
                    }
                    this.imagesArray.push({
                        fileObject: file,
                        type: file.type,
                        name: file.name,
                        size: file.size
                    });
                    this.filesArray.push({
                        type: file.type,
                        name: file.name,
                        size: file.size
                    });

                    if (index === array.length - 1) {
                        if (this.validateImages(this.filesArray)) {
                            this.setStateFilesToQuestion();
                            this.updateAnswer();
                        }
                    }
                });
            }
        },
        validateImageFileExtensionsAndSize(files) {
            const supportedTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpe', 'image/jpeg', 'image/tiff', 'video/quicktime', 'video/mov', 'video/mp4', 'video/webm', 'video/mpeg', 'video/qt', 'video/ogv', 'video/mkv', 'application/pdf'];
            const maxFileSize = 20971520;
            const maxVideoSize = 99097152;
            let isValid;
            files.forEach((file) => {
                if (supportedTypes.includes(file.type.toLowerCase())) {
                    isValid = true;
                } else {
                    isValid = false;
                    alert(this.$t('file.error_unsupported_filetype', { fileType: file.type.toLowerCase() }));
                }
                if (file.size > maxFileSize && (file.type.toLowerCase().includes('image') || file.type.toLowerCase().includes('pdf'))) {
                    isValid = false;
                    alert(this.$t('file.error_image_too_large'));
                }
                if (file.size > maxVideoSize && file.type.toLowerCase().includes('video')) {
                    isValid = false;
                    alert(this.$t('file.error_video_too_large'));
                }
            });
            return isValid;
        },
        validateFileName(files) {
            let isValid = false;
            files.forEach((file) => {
                if (file.name.includes(',')) {
                    isValid = false;
                    alert(this.$t('file.error_filename'));
                } else {
                    isValid = true;
                }
            });
            return isValid;
        },
        validateImages(files) {
            this.isErrorImagesLow = !!(this.min && files.length < this.min);

            if (this.max > 0 && files.length > this.max) {
                this.isErrorImagesMany = true;
                this.isErrorImagesLow = false;
            } else {
                this.isErrorImagesMany = false;
            }

            return true;
        },
        updateAnswer() {
            let imageNames = [];
            let values = '';
            if (this.imagesArray.length > 0) {
                imageNames = this.imagesArray.reduce((acc, file) => {
                    if (file) {
                        acc.push(file.name);
                    }
                    return acc;
                }, []);
            }
            if (imageNames.length > 0) {
                values = imageNames.join(',');
            }
            this.$emit('updateAnswer', values);
        }
        ,
        onUploadImages() {
            this.$refs.upload.$refs.input.click();
        }
        ,
        deleteImages() {
            this.showConfirmDeleteImages = false;
            this.imagesArray = [];
            this.setStateFilesToQuestion(true);
            this.validateImages(this.imagesArray);
            this.updateAnswer();
        }
        ,
        onRemoveFile() {
            this.showConfirmDeleteImages = false;
            this.imagesArray.splice(this.currentImageIndex, 1);
            this.setStateFilesToQuestion(true);
            this.validateImages(this.imagesArray);
            this.updateAnswer();
            this.currentImageIndex = null;
        }
        ,
        renderImage(image) {
            const tempUrl = URL.createObjectURL(image);
            URL.revokeObjectURL(image);
            return tempUrl;
        }
        ,
        setStateFilesToQuestion(shouldDelete = false) {
            this.setFilesToQuestion({
                questionId: this.questionId,
                images: this.imagesArray,
                shouldDelete: shouldDelete
            });
        }
        ,
        checkForAnswer() {
            if (this.filesToQuestions) {
                this.filesToQuestions.forEach((file, index, array) => {
                    if (file.questionId === this.questionId) {
                        file.images.forEach((file) => {
                            this.imagesArray.push(file);
                        });
                    }
                    if (index === array.length - 1) {
                        this.file = this.imagesArray;
                        this.validateImages(this.imagesArray);
                    }
                });
            }
        }
    },
    created() {
        if (this.surveyMode === 'resume-survey') {
            this.checkForAnswer();
        }
    }
};
</script>

<style>
.survey__question__media .v-input__slot,
.survey__question__media .v-input__prepend-outer {
    display: none !important;
}
</style>
